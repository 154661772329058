import Configurator from "./components/Configurator.vue";
import "./plugins/vuetify";
import store from "./store";
import { mapState } from "vuex";
export default {
  store,
  name: "App",
  components: {
    Configurator
  },

  data() {
    return {
      appDark: false,
      showPopUp: false,
      dontShowAgain: false,
      isInDateRange: false,
      country: ""
    };
  },

  mounted: async function () {
    let cc = await this.$rest.getCountryCode();
    this.country = cc.data;
    const startDateEstero = new Date("2024-12-12");
    const dontShow = localStorage.getItem("dontShow");
    const startDate = new Date("2024-12-01"); // Inizio intervallo

    const endDate = new Date("2025-01-07"); // Fine intervallo

    const currentDate = new Date();
    this.isInDateRange = currentDate >= startDate && currentDate <= endDate;

    if (this.country === "IT") {
      this.isInDateRange = currentDate >= startDate && currentDate <= endDate;
    } else {
      this.isInDateRange = currentDate >= startDateEstero && currentDate <= endDate;
    }

    if (this.isInDateRange && (!dontShow || dontShow === "false")) {
      this.showPopUp = true;
    } // eslint-disable-next-line no-undef


    gtag("config", this.gtagId, {
      custom_map: {
        dimension1: "aste",
        dimension2: "coloreMetalloAste",
        dimension3: "finitura",
        dimension4: "asianFitting",
        dimension5: "coloreFrontale",
        dimension6: "misura",
        dimension7: "finituraAste",
        dimension8: "nomePersonalizzato",
        dimension9: "coloreNomePersonalizzato"
      }
    });
  },
  computed: { ...mapState(["gtagId"])
  },
  methods: {
    closePopUp() {
      // Salva la preferenza dell'utente nel localStorage
      if (this.dontShowAgain) {
        localStorage.setItem("dontShow", "true");
      }

      this.showPopUp = false;
    },

    changePopUp() {
      this.showPopUp = !this.showPopUp; // Cambia lo stato del popup
    }

  },
  watch: {
    appDark: function (val) {
      this.$vuetify.theme.dark = val;
    }
  }
};